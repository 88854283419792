import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import CardMedia from '@mui/material/CardMedia';

import Headline from '../components/blocks/shared/Headline';

import BendableLogo from '../images/logo-bendable.svg';
import BendableParentCenters from '../images/program-background_bendable-parent-centers.png';
import JRRLogo from '../images/program-logo_job-readiness-room.png';
import LightcastLogo from '../images/program-logo_lightcast.png';
import HarborFreightBackground from '../images/program-background_harbor-freight.png';
import CompanyRankingsLogo from '../images/program-logo_company-rankings.png';
import ConsultingBackground from '../images/bl_consulting.png';

import { COLORS, SPACING } from '../theme.js';

const ProgramsAndProductsPage = (props) => {
  const linkStyle = {
    textDecoration: "underline",
    color: COLORS.BLACK
  }

  return (
    <Stack>
      <Headline text="Our programs and products" />
      <Stack
        sx={{
          paddingLeft: SPACING.MAIN_LEFT,
          paddingRight: SPACING.MAIN_RIGHT,
          paddingBottom: '70px'
        }}
      >
        <Divider sx={{ marginBottom: '70px' }} />
        <Grid container spacing={4}
          sx={{
          }}
        >
          {/* Bendable */}
          <Grid item xs={12} sm={4} md={5} lg={5} xl={5}>
            <Box
              sx={{
                backgroundColor: COLORS.BG_DARKBLUE,
                color: "white",
                height: {xs: '200px', sm: '200px', md: '266px', lg: '266px'},
                padding: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img className="responsive-image" src={BendableLogo} alt-text="Bendable Logo" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
            <Typography
              sx={{
                lineHeight: 1.5,
                fontFamily: "Sharp Sans",
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: {xs: '.8rem', sm: '.8rem', md: '.95rem', lg: '1rem'},
              }}
            >
              <Link href="https://bendable.com/" target="_blank" underline="none" sx={linkStyle}>Bendable</Link>
              &nbsp;is a digital platform that transforms the way underserved communities connect
              to the resources they need. It ensures that key opportunities, services and benefits reach the
              people who need them the most, whether they are coming into a public library, job center, community center, 
              family resource center or senior living program. Chief among these are learning and skill-building opportunities.
            </Typography>
            <Box
              sx={{
                paddingTop: "10px"
              }}
            >
            </Box>
          </Grid>

          {/* Bendable for Parent Centers */}
          {/* <Grid item xs={12} sm={4} md={5} lg={5} xl={5} sx={{ display: 'none' }}>
            <Box
              sx={{
                height: {xs: '200px', sm: '200px', md: '266px', lg: '266px'},
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"                
              }}
            >
              <CardMedia
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'flex-end'                  
                }}
                image={BendableParentCenters}
              >
                <Box
                  sx={{
                    backgroundColor: COLORS.BG_LIGHTGOLD,
                    padding: '12px',
                    height: { xs: '45px', sm: '70px', md: '80px' },
                    width: '100%'
                  }}
                >
                  <Typography sx={{
                    color: "white",
                    fontFamily: "cooper-black-std",
                    fontSize: { sm: '1.2rem', md: '1.4rem', lg: '1.6rem' }
                  }}>
                    Bendable
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Sharp Sans",
                      fontSize: { sm: '.9rem', md: '1rem', lg: '1.1rem' }
                    }}
                  >
                    for Parent Centers
                  </Typography>
                </Box>
              </CardMedia>
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} sm={8} md={7} lg={7} xl={7} sx={{ display: 'none' }}>
            <Typography
              sx={{
                fontFamily: "Sharp Sans",
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: {xs: '.8rem', sm: '.8rem', md: '.95rem', lg: '1rem'},
                lineHeight: 1.5
              }}
            >
              An adaptation of the library platform, <Link href="https://salcastro.bendable.com/" target="_blank" underline="none" sx={linkStyle}>Bendable for school parent centers</Link> is 
              designed to meet the learning needs of parents, families and caregivers of 
              K-12 students. The classes found there enable parents to continue learning 
              for personal enrichment, boost their careers, set a good example for their 
              kids and help their families thrive.
            </Typography>
            <Box
              sx={{
                paddingTop: "10px"
              }}
            >
            </Box>
          </Grid> */}

          {/* The JRR */}
          {/* <Grid item xs={12} sm={4} md={5} lg={5} xl={5} sx={{ display: 'none' }}>
            <Box
              sx={{
                backgroundColor: "#549C51",
                color: "white",
                height: {xs: '200px', sm: '200px', md: '266px', lg: '266px'},
                padding: "24px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <Box
                sx={{
                  height: "80px"
                }}
              >
                <img className="responsive-image" src={JRRLogo} alt-text="Job Readiness Logo" />
              </Box>
              <Box 
                sx={{
                  display: "flex",
                  flexDirection: {xs: "row", sm: "column", md: "column", lg: "row"},
                  alignItems: {xs: "flex-end", sm: "flex-end", md: "center", lg: "flex-end"},
                  justifyContent: {xs: "flex-end", sm: "center", md: "flex-end", lg: "center"},
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    marginRight: {md: "0", lg: "12px"},
                    marginTop: {md: "12px", lg: "0"}
                  }}
                >
                  Powered by
                </Typography>
                <img className="responsive-image" style={{height: "40%", width: "auto"}} src={LightcastLogo} alt-text="Lightcast Logo" />
              </Box>
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} sm={8} md={7} lg={7} xl={7} sx={{ display: 'none' }}>
            <Typography
              sx={{
                lineHeight: 1.5,
                fontFamily: "Sharp Sans",
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: {xs: '.8rem', sm: '.8rem', md: '.95rem', lg: '1rem'},
              }}
            >
              <Link href="https://northcounty.jobreadinessroom.com" target="_blank" underline="none" sx={linkStyle}>
              The Job-Readiness Room</Link>, gives small and midsize enterprises 
              access to job-readiness training for their new entry-level hires, 
              while also letting local employers tap a library-based version of 
              the platform to help find qualified entry-level job candidates.
              The program was created by Bendable Labs in partnership 
              with <Link href="https://lightcast.io/solutions/economic-and-workforce-development/job-readiness-room" target="_blank" underline="none" sx={linkStyle}>
              Lightcast</Link>, the nation’s leader in labor market analytics.
            </Typography>
          </Grid> */}

          {/* Harbor Freight Leadership Lab */}
          <Grid item xs={12} sm={4} md={5} lg={5} xl={5}>
            <Box
              sx={{
                height: {xs: '200px', sm: '200px', md: '266px', lg: '266px'},
                display: "flex",
              }}
            >
              <CardMedia
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'flex-end',
                  textAlign: 'center'
                }}
                image={HarborFreightBackground}
              >
                <Box
                  sx={{
                    backgroundColor: COLORS.BG_LIGHTGOLD,
                    padding: '12px',
                    height: { xs: '50px', sm: '80px' },
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: {xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.2rem'},
                    }}
                  >
                    Harbor Freight<br/>Leadership Lab
                  </Typography>
                </Box>
              </CardMedia>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
            <Typography
              sx={{
                lineHeight: 1.5,
                fontFamily: "Sharp Sans",
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: {xs: '.8rem', sm: '.8rem', md: '.95rem', lg: '1rem'},
              }}
            >
              Harbor Freight Leadership Lab, which Bendable Labs runs with&nbsp;
              <Link href="https://hftforschools.org/" target="_blank" underline="none" sx={linkStyle}>
              Harbor Freight Tools for Schools</Link>, brings together leaders from
              across the K-12 skilled trades education ecosystem to deliver
              practical tools for their career advancement, deepen their
              connection to each other and elevate the sector’s performance,
              prestige and standing in society.
            </Typography>
          </Grid>

          {/* Drucker Institute Company Rankings */}
          <Grid item xs={12} sm={4} md={5} lg={5} xl={5}>
            <Box
              sx={{
                backgroundColor: COLORS.BG_RED,
                color: "white",
                height: {xs: '200px', sm: '200px', md: '266px', lg: '266px'},
                padding: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <img className="responsive-image-vertical" src={CompanyRankingsLogo} alt-text="Company Rankings Logo" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
            <Typography
              sx={{
                fontFamily: "Sharp Sans",
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: {xs: '.8rem', sm: '.8rem', md: '.95rem', lg: '1rem'},
                lineHeight: 1.5
              }}
            >
              Bendable Labs calculates the scores that underpin the Drucker 
              Institute’s <Link href="https://drucker.institute/company-rankings/" target="_blank" underline="none" sx={linkStyle}>company rankings</Link>, which 
              serve as the basis for, among other things, <i>The Wall Street Journal’s</i> annual 
              annual best-managed companies list, the “Management Top 250.” The 
              rankings were co-created in 2017 by Rick Wartzman, now of Bendable 
              Labs, to help spur companies to move away from invariably putting 
              the interests of their shareholders ahead of those of their workers 
              and other stakeholders.
            </Typography>
          </Grid>

          {/* Research and Consulting */}
          <Grid item xs={12} sm={4} md={5} lg={5} xl={5}>
            <Box
              sx={{
                height: {xs: '200px', sm: '200px', md: '266px', lg: '266px', xl: '266px'},
                display: "flex",
              }}
            >
              <CardMedia
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'flex-end',
                  textAlign: 'center',
                  backgroundPositionY: {xs: '-50px', sm: '-50px', md: '-50px', lg: '-80px', xl: '-200px'},
                }}
                image={ConsultingBackground}
              >
                <Box
                  sx={{
                    backgroundColor: COLORS.BG_LIGHTGOLD,
                    padding: '12px',
                    height: { xs: '50px', sm: '80px' },
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: {xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.2rem'},
                    }}
                  >
                    Research and Consulting
                  </Typography>
                </Box>
              </CardMedia>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
            <Typography
              sx={{
                lineHeight: 1.5,
                fontFamily: "Sharp Sans",
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: {xs: '.8rem', sm: '.8rem', md: '.95rem', lg: '1rem'},
              }}
            >
              Bendable Labs provides research, strategy consulting and community-of-practice facilitation in the areas of learning and work. 
              Clients include <Link href="https://hftforschools.org/" target="_blank" underline="none" sx={linkStyle}>Harbor Freight Tools for Schools</Link>,&nbsp; 
              <Link href="https://www.menloedu.org/" target="_blank" underline="none" sx={linkStyle}>Menlo Education Research</Link>,&nbsp;the <Link href="https://sos.tn.gov/tsla/" target="_blank" underline="none" sx={linkStyle}>Tennesse State Library & Archives</Link>&nbsp;and <Link href="https://www.calbright.edu" target="_blank" underline="none" sx={linkStyle}>Calbright</Link>.
            </Typography>
          </Grid>



        </Grid>
      </Stack>
    </Stack>
  )
}

export default ProgramsAndProductsPage;
