import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import WhiteLogo from '../../images/bendable-labs-logo_white.svg';
import AnthemAward from '../../images/anthem_award.png';

import { SPACING } from '../../theme';

const Footer = (props) => {
  return (
    <Stack
      id="app-footer"
      component="footer"
      sx={{
        background: '#008AAF',
        color: 'white',
        paddingTop: { xs: '30px', lg: '45px' },
        paddingLeft: SPACING.MAIN_LEFT,
        paddingRight: SPACING.MAIN_RIGHT,
        paddingBottom: { xs: '30px', lg: '45px' },
        fontFamily: 'CooperBTMedium',
      }}
    >
      <Box
        sx={{
          height: { xs: '32px' , md: '64px' },
          flexGrow: 1,
          marginBottom: { xs: '30px', lg: '45px' },
        }}
      >
        <img src={WhiteLogo} height="100%" alt="bendable logo" />
      </Box>

      <Stack
        direction='row'
        sx={{
          marginBottom: { xs: '0px', lg: '0px' },
        }}
      >
        <Box sx={{ flexGrow: '1' }}>
          <Link
            href="mailto: info@bendablelabs.com"
            underline='none'
          >
            <Typography variant='h5'>info@bendablelabs.com</Typography>
          </Link>

          <Typography variant='h5' sx={{ flexGrow: '1' }}>
            323-896-0626
          </Typography>
        </Box>

        <Box
          sx={{
            height: { xs: '80px' , md: '120px', lg: '120px', xl: '150px', },
          }}
        >
          <img src={AnthemAward} height="100%" alt="bendable logo" />
        </Box>
      </Stack>

      <Divider sx={{ backgroundColor: 'white' }} />

      <Typography variant="h5"
        sx={{
          marginTop: { xs: '30px', lg: '45px' },
        }}
      >
        Site design: Kilter
      </Typography>
    </Stack>
  )
}

export default Footer;
