import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';

import Baker from '../../../images/partner-logo_baker-taylor.png';
import Harbor from '../../../images/partner-logo_harbor-freight.png';
import Burning from '../../../images/partner-logo_bgi-institute.png';
import Lightcast from '../../../images/partner-logo_lightcast.png';
import Csl from '../../../images/partner-logo_california-state-library.png';
import Epg from '../../../images/partner-logo_epg.png';
import Nwtw from '../../../images/partner-logo_nwtw.png';
import Tennessee from '../../../images/partner-logo_tennessee.png';
import Nifb from '../../../images/partner-logo_nifb.png';
import Lausd from '../../../images/partner-logo_lausd.png';
import Calbright from '../../../images/partner-logo_calbright.png';
import Wsj from '../../../images/partner-logo_wsj.png';

import { SPACING, MOBILE_WIDTH } from '../../../theme';

import './Partners.css'


const Partners = (props) => {
  const theme = useTheme();

  //const [width, setWidth] = useState(window.innerWidth);

  // function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  // }

  // useEffect(() => {
  //     window.addEventListener('resize', handleWindowSizeChange);
  //     return () => {
  //         window.removeEventListener('resize', handleWindowSizeChange);
  //     }
  // }, []);  
  // const isMobile = width <= MOBILE_WIDTH;  // MaterialUI SM equivalent
  // const numCols = !isMobile ? 3 : 2;
  // const rowHeight = !isMobile ? 80 : 60;

  const logos = [
    { name: "The Burning Glass Institute", src: Burning },
    { name: "Harbor Freight", src: Harbor },
    { name: "Tennessee State Library", src: Tennessee },
    { name: "California State Library", src: Csl },
    { name: "Calbright College", src: Calbright },
    { name: "Wall Street Journal Leadership Institute", src: Wsj },
    { name: "Lightcast", src: Lightcast },
    { name: "Baker & Taylor", src: Baker },
    { name: "LAUSD Unified", src: Lausd },
    { name: "Employ Prince George's", src: Epg },
    { name: "Northern Illinois Food Bank", src: Nifb },
    { name: "New Ways to Work", src: Nwtw },
  ];

  let numCols = 2;
  if (useMediaQuery(theme.breakpoints.up('sm'))) numCols = 3;

  let gap = 20;
  if (useMediaQuery(theme.breakpoints.up('sm'))) gap = 30;
  if (useMediaQuery(theme.breakpoints.up('md'))) gap = 30;
  if (useMediaQuery(theme.breakpoints.up('lg'))) gap = 30;

  let rowHeight = 60;
  if (useMediaQuery(theme.breakpoints.up('lg'))) rowHeight = 80;



  return (
    <Stack
      sx={{
        marginTop: { xs: '40px', sm: '40px', md: '60px', lg: '80px', },
        marginLeft: SPACING.MAIN_LEFT,
        marginRight: SPACING.MAIN_RIGHT,
        marginBottom: { xs: '60px', sm: '100px' },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          // paddingBottom: { xs: '0px', sm: '20px', md: '40px', },
        }}
      >
        Select partners & customers
      </Typography>

      <Typography
        sx={{
          fontFamily: 'CooperBTMedium',
          color: '#303836',
          fontStyle: 'normal',
          fontWeight: 600,
          paddingBottom: { xs: '0px', sm: '20px', md: '40px', },
        }}
      >
        Past and present
      </Typography>

      
      <ImageList
        cols={numCols}
        gap={gap}
        rowHeight={rowHeight}
        sx={{
          width: { xs: 300, sm: 600, md: 700, lg: 900, xl: 1600 },
          height: { xs: 500, sm: 350, md: 400, lg: 450, xl: 450 },
        }}
      >
        {logos.map((logo, index) => (
            <ImageListItem key={`${index}`} rows={1} cols={1}>
              <img src={logo.src} alt={logo.name}
                loading="lazy"
                style={{ height: rowHeight, objectFit: 'contain' }}
              />
            </ImageListItem>
          )
        )}
      </ImageList>

    </Stack>
  )
}

export default Partners;
