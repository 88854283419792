import React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'

import Headline from '../components/blocks/shared/Headline.js';
import LaptopLearner from '../images/laptop_learner.png';
import Hfll22 from '../images/hfll22.png';
import JrrScreen from '../images/jrr_screen.png';
import DeiLeader from '../images/dei_leader.png';

import { COLORS, SPACING } from '../theme.js';

const SocialPurposeAnnualReportPage = (props) => {

  return (
    <Stack>
      <Headline text="2024 annual report" />
      <Stack
        sx={{
          paddingLeft: SPACING.MAIN_LEFT,
          paddingRight: { xs: '40px', sm: '80px', md: '120px', lg: '160px', xl: '200px' },
          lineHeight: '1.5'
        }}
      >
        <Divider
          sx={{
            marginBottom: { xs: '10px', sm: '10px', md: '20px', lg: '30px', xl: '30px' },
          }}
        />

        <Typography
          component="div"
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          <div style={{
            float: 'left',
            fontSize: '4.6rem',
            lineHeight: '3rem',
            paddingTop: '15px',
            paddingRight: '4px'
          }}>B</div>endable Labs is registered with the State of California as a&nbsp;
          <Link
            href="http://www.leginfo.ca.gov/pub/13-14/bill/sen/sb_1301-1350/sb_1301_cfa_20140621_181747_asm_comm.html"
            // underline="none"
            target="_blank"
            rel="noreferrer"
            sx={{
              color: '#297687',
            }}
          >
            social purpose corporation
          </Link>, a designation intended to give companies greater flexibility to prioritize pursuing the public
          good in addition to generating profits.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          In 2024, our second year of operation since spinning out of the Drucker Institute at Claremont Graduate University, we continued to meet our mission—improving economic stability, mobility and opportunity for people across the United States—by offering our resource-sharing platform, Bendable, and consulting with organizations focused on learning and work.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          On the technology front, we made significant advances in 2024. We launched v2.0 of the Bendable platform, streamlining the user experience for both admins and end users and allowing new Bendable customers to come online with their own customizable site in a matter of hours.
        </Typography>

        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Typography
            component="div"
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.0rem',
              fontColor: '#000000',
              marginBottom: '20px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '290px', md: '446px', },
                // height: { xs: '195px', md: '300px', },
                float: 'right',
                marginLeft: { xs: '10px', sm: '20px', },
                marginBottom: { xs: '20px', sm: '10px' },
              }}
              alt="NIFB site"
              src="https://bendable.s3.us-west-1.amazonaws.com/NIFB_homepage.png"
            />
            Responding to the needs of our customers, we also began highlighting the platform’s ability for a community organization to share a wide range of essential resources with those it serves. Since it was introduced in 2020, Bendable has centered on making learning resources easily discoverable and accessible—and thousands of classes from trusted content partners on job-readiness, personal finance, digital literacy and well-being remain at the heart of the product. But we refined Bendable so that it can feature, in addition to online courses, links to community services and other vital information.
            <br/><br/>
            These enhancements propelled strong growth. By the end of 2024, we had sold Bendable for use in 80 locations in 19 states, plus Washington, D.C. Among them: public libraries, job centers, low-income housing complexes, a major food bank, K-12 parent centers, senior living communities and a financial services provider that is aiming to improve the lives of residents in inner-city neighborhoods.
          </Typography>
        </Stack>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          Meanwhile, we enlisted New Ways to Work to help us assess how effective Bendable is at helping community organizations increase their clients’ resource utilization. That evaluation will be completed in 2025.
        </Typography>

        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Typography
            component="div"
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.0rem',
              fontColor: '#000000',
              marginBottom: '20px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '290px', md: '453px', },
                // height: { xs: '192px', md: '300px', },
                float: 'left',
                marginRight: { xs: '10px', sm: '20px', },
                marginBottom: { xs: '20px', sm: '10px' },
              }}
              alt="HFLL Alumni"
              src="https://bendable.s3.us-west-1.amazonaws.com/Group_2024.jpg"
            />
            On the consulting side of the business, we continued our longstanding collaboration with Harbor Freight Tools for Schools and the Smidt Foundation. The fourth cohort of the Harbor Freight Leadership Lab saw 16 handpicked individuals from the worlds of education, industry and labor meet online and in person to acquire practical management and leadership tools that can help boost their careers while elevating the skilled trade sector’s performance, prestige and standing in society.
            <br/><br/>
            Those taking part in the lab have made it clear that they don’t want the experience to end at the conclusion of their cohort year. And so we added a new element to the program in 2024: an alumni network that allows HFLL participants to continue to learn with and from each other, as well as be part of a broader movement to help make all young people in America future-ready and break the stranglehold that “college for all” has on our education system and society at large.
          </Typography>

        </Stack>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          We continued our work, as well, with Menlo Education Research to facilitate knowledge-sharing among members of the Advancing Innovative Math Solutions (AIMS) Collaboratory. This community of practice is composed of cross-sector teams committed to lifting up mathematics education, particularly for students of color and students affected by poverty. The project, which expanded in 2024 to include 81 organizations, is funded by the Bill & Melinda Gates Foundation.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          Late in the year, we embarked on an exciting new project, as we began the process of equipping 18 public libraries in the western part of Tennessee to play a more effective role in their local workforce development ecosystems. The initiative, which is being funded by the Tennessee State Library & Archives, will bring to life a model that we laid out in 
          a <Link
            href="https://www.burningglassinstitute.org/research/library-workforce-development"
            // underline="none"
            target="_blank"
            rel="noreferrer"
            sx={{
              color: '#297687',
            }}
          >
            March 2024 report
          </Link> for the Burning Glass Institute.
        </Typography>

        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.0rem',
              fontColor: '#000000',
              marginBottom: '20px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '295px', md: '446px', },
                // height: { xs: '151px', md: '228px', },
                float: 'left',
                marginRight: { xs: '10px', sm: '20px', },
                marginBottom: { xs: '20px', sm: '10px' },
              }}
              alt="Madison County Library"
              src="https://bendable.s3.us-west-1.amazonaws.com/Madison_County_Library.jpg"
            />
            Under the Library-based Employment Growth and Upskilling Program, or LegUp, area residents can learn foundational job-readiness skills (time management, professionalism, teamwork and so on), with a capstone course in one of three fields: manufacturing, warehousing or healthcare. By completing the 16 hours of online training, they are able to demonstrate to an employer their perseverance and willingness to learn. Employers that join the LegUp program agree to guarantee interviews to those who have gone through the training and have thereby earned a job-readiness microcredential backed by the State Library. (Whether a company ultimately wants to make a hire is entirely up to them.) LegUp is set to launch in June 2025.
            <br/><br/>
            In December, The Wall Street Journal published its eighth annual “Management Top 250” rankings—part of an effort to spur large U.S. companies to go beyond serving the interests of their shareholders and embrace stakeholder capitalism. The rankings are derived from a holistic measurement of corporate effectiveness created by the Drucker Institute. Bendable Labs works with the institute to perform the calculations and interpret them.
          </Typography>
        </Stack>


        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Typography
            component="div"
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.0rem',
              fontColor: '#000000',
              marginBottom: '20px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '290px', md: '457px', },
                // height: { xs: '206px', md: '324px', },
                float: 'right',
                marginLeft: { xs: '10px', sm: '20px', },
                marginBottom: { xs: '20px', sm: '10px' },
              }}
              alt="DEI article thumbnail"
              src={DeiLeader}
            />
            We also conducted rankings-related research to generate new insights into effective corporate management. Subjects included the benefits of hybrid jobs that combine working from home with time in the office, how investing in positions to tackle climate change can foster employee engagement and innovation, and the importance of encouraging worker voice.
            <br/><br/>
            Finally, we received some notable recognition in 2024.The International Academy of Digital Arts and Sciences named Bendable Labs as a bronze winner in the fourth annual Anthem Awards. We were recognized in the “Responsible Technology - Partnership or Collaboration” category for a job-readiness pilot program housed at the Carlsbad City Library in Southern California. Learnings from that project are being used to inform our work in Tennessee. Those honored alongside Bendable Labs included AARP, Samsung and the United Nations Development Programme, and UNICEF USA.
          </Typography>
        </Stack>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '60px',
          }}
        >
          All of our operating expenses for 2024—$1.4 million—went toward furthering our social purpose.
        </Typography>

      </Stack>
    </Stack>
  );
}

export default SocialPurposeAnnualReportPage;
